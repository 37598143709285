export const OpcaStomatologija = [
  { usluga: "Kompozitni ispun/Composite filling", cijena: "od 45€" },
  { usluga: "Krunice/Dental crowns", cijena: "od 250€" },
  { usluga: "Endodoncija/Root canal treatment", cijena: "od 80€" },
  { usluga: "Proteze/Acrylic denture", cijena: "od 510€" },
  { usluga: "Ljuskice/Veneers", cijena: "od 240€" },
  { usluga: "Vađenje zuba/Extractions", cijena: "45€" },
  { usluga: "Skidanje kamenca/Scaling", cijena: "45€" },
  { usluga: "Zubni nakit/Tooth jewelry", cijena: "60€" },
  { usluga: "Izbjeljivanje zuba/Whitening", cijena: "240€" },
]

export const EstetskaMedicina = [
  { usluga: "Dermalni fileri/Dermal fillers", cijena: "od 155€" },
  { usluga: "PRP", cijena: "od 180€" },
  { usluga: "Neuromodulatori", cijena: "od 175€" },
  { usluga: "Polinukleotidi", cijena: "220€" },
  { usluga: "Mezoterapija/Mesotherapy", cijena: "od 220€" },
  { usluga: "Profhilo", cijena: "300€" },
  { usluga: "RRS kokteli/RRS COCTAILS", cijena: "od 110€" },
  { usluga: "Dermapen/DERMAPEN", cijena: "od 110€" },
]
